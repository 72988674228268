/* eslint-disable indent */
import { TradePriceInfo } from '@/types'
import classNames from 'classnames'
import { OnScreenComponent } from 'components/abstract/OnScreenComponent'
import { ArtworkArtistsNames } from 'components/artwork/ArtworkArtistsNames'
import {
  ArtworkArtistSkeleton,
  ArtworkImageSkeleton,
  ArtworkNameSkeleton,
  ArtworkPriceSkeleton,
} from 'components/artwork/ArtworkSkeleton'
import { Price } from 'components/common/Price'
import { Currencies } from 'constants/currencies'
import { useCurrencies } from 'contexts/hooks/useCurrencies'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { ReactNode, useMemo, useState } from 'react'
import { pickTradePrice } from 'utils/currencies'
import { clearScroll } from 'utils/scroll'

export type ArtworkCardProps = {
  id?: number
  className?: string
  src?: string
  artworkName?: string
  artists?: { id: number; name: string }[]
  artistName?: string
  // buyPrice?: number | null
  // buyCurrency?: Currency | null
  // sellPrice?: number | null
  // sellCurrency?: Currency | null
  lastSale?: number
  href?: string
  medium?: string | null
  releaseDate?: string | null
  editionName?: string | null
  onLinkClick?: () => void
  saveButton?: ReactNode
  priority?: boolean
  additionalSrc?: {
    medium?: string
  }
  lowestAsk?: TradePriceInfo | null
  highestBid?: TradePriceInfo | null
}

export const ArtworkCard = ({
  id,
  className,
  src,
  artworkName,
  artists,
  lastSale,
  href = '#',
  onLinkClick,
  saveButton,
  priority,
  additionalSrc,
  lowestAsk,
  highestBid,
}: ArtworkCardProps) => {
  const { t } = useTranslation()
  const [imgLoaded, setImgLoaded] = useState(false)
  const { userCurrency, isLoadingUserCurrency, selectedCurrency } =
    useCurrencies()

  const { buyPrice, sellPrice } = useMemo(() => {
    if (isLoadingUserCurrency) {
      return { buyPrice: null, sellPrice: null }
    }

    return {
      buyPrice: lowestAsk ? pickTradePrice(userCurrency, lowestAsk) : null,
      sellPrice: highestBid ? pickTradePrice(userCurrency, highestBid) : null,
    }
  }, [
    isLoadingUserCurrency,
    userCurrency,
    selectedCurrency,
    lowestAsk,
    highestBid,
  ])

  const onClick = () => {
    if (onLinkClick) {
      onLinkClick()
      return
    }

    clearScroll()
  }

  const imgProps = {
    className: 'object-contain',
    fill: true,
    quality: 75,
    priority,
    onLoad: () => setImgLoaded(true),
  } as const

  const imgEl =
    src && artworkName ? (
      <div className='relative h-full w-full'>
        {!imgLoaded && !priority && <ArtworkImageSkeleton />}

        {additionalSrc?.medium && (
          <Image
            src={additionalSrc.medium}
            alt={artworkName}
            {...imgProps}
            className='hidden object-contain lg:block'
            sizes='(max-width:1023px) 0vw, 15vw'
          />
        )}

        <Image
          src={src}
          alt={artworkName}
          {...imgProps}
          className={classNames(
            'object-contain',
            additionalSrc?.medium && 'lg:hidden'
          )}
          sizes={
            additionalSrc?.medium ? '(max-width:1023px) 15vw, 0vw' : '15vw'
          }
        />
      </div>
    ) : (
      <ArtworkImageSkeleton />
    )

  return (
    <div className={`group ${className}`} id={id ? `artwork-${id}` : undefined}>
      <Link
        href={href}
        prefetch={false}
        title={artworkName}
        className='[&_*]:pointer-events-none'
        onClick={onClick}
        data-e2e='artwork-card-link'
      >
        <div className='aspect-h-5 aspect-w-4 relative flex items-center justify-center rounded-[4px] bg-warm-50'>
          <div className='flex items-center justify-center'>
            <div className='flex h-13/20 w-13/20 items-center duration-300 ease-in-out group-hover:scale-105'>
              {!priority && (
                <OnScreenComponent
                  className='relative flex h-full w-full items-center drop-shadow-[0_4px_5px_rgba(38,35,56,0.15)]'
                  fallback={<ArtworkImageSkeleton />}
                >
                  {imgEl}
                </OnScreenComponent>
              )}

              {priority && (
                <div className='relative flex h-full w-full items-center drop-shadow-[0_4px_5px_rgba(38,35,56,0.15)]'>
                  {imgEl}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='mt-4 flex items-center justify-between space-x-3'>
          {artworkName ? (
            <p className='overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium sm:text-base'>
              {artworkName}
            </p>
          ) : (
            <ArtworkNameSkeleton />
          )}

          {saveButton}
        </div>
      </Link>

      <div className='mb-2.5' onClick={clearScroll}>
        {artists ? (
          <>
            <div className='hidden md:block'>
              <ArtworkArtistsNames artists={artists} />
            </div>

            <div className='md:hidden'>
              <ArtworkArtistsNames artists={artists} clickable={false} />
            </div>
          </>
        ) : (
          <ArtworkArtistSkeleton />
        )}
      </div>

      {artworkName && !isLoadingUserCurrency ? (
        <Link
          href={href}
          prefetch={false}
          className='artwork-card__buttons mb-[2px] flex flex-row [&_*]:pointer-events-none'
          onClick={onClick}
        >
          {!lastSale && (
            <>
              {[
                { label: 'buy', value: buyPrice },
                { label: 'sell', value: sellPrice },
              ].map(({ label, value }, index) => {
                const itemClassName =
                  label === 'buy'
                    ? 'mr-2 border-emerald-800 bg-emerald-800'
                    : 'border-danger-dark bg-chili-800'

                const datae2e = value
                  ? `artwork-card-${label}-value`
                  : undefined

                return (
                  <div
                    key={index}
                    className={classNames(
                      'flex w-max flex-row justify-between overflow-hidden truncate whitespace-nowrap rounded border px-2 py-1 text-xs font-medium text-cool-50',
                      itemClassName
                    )}
                  >
                    <Price
                      data-e2e={datae2e}
                      formattingOptions={{
                        notation: 'compact',
                        maximumFractionDigits: 1,
                        from: Currencies.USER,
                        to: Currencies.USER,
                      }}
                    >
                      {(value || 0) / 100}
                    </Price>
                  </div>
                )
              })}
            </>
          )}

          {!!lastSale && (
            <span>
              <span className='text-sm text-cool-600'>
                {t('common:last_sale')}
              </span>{' '}
              <span className='text-base font-medium'>
                {/* NOTE: The backend price is in pence, but the frontend is in pounds */}
                <Price
                  formattingOptions={{ to: userCurrency, from: userCurrency }}
                >
                  {lastSale / 100}
                </Price>
              </span>
            </span>
          )}
        </Link>
      ) : (
        <div className='flex space-x-2'>
          <ArtworkPriceSkeleton />

          <ArtworkPriceSkeleton />
        </div>
      )}
    </div>
  )
}
