import Link from 'next/link'
import { Fragment } from 'react'
import { NamedEntity } from 'types'
import { getArtistUrl } from 'utils/urls'

type Props = {
  artists: NamedEntity[]
  textColor?: string
  textClassName?: string
  clickable?: boolean
}

export const ArtworkArtistsNames = ({
  artists,
  textColor = 'text-cool-600',
  textClassName,
  clickable = true,
}: Props) => {
  const className = `text-sm ${
    clickable && 'hover:text-secondary-default'
  } ${textColor} ${textClassName}`

  return (
    <div className='flex space-x-1 overflow-hidden overflow-ellipsis whitespace-nowrap '>
      {artists?.map((artist, index) => (
        <Fragment key={artist.id}>
          {clickable ? (
            <Link
              href={getArtistUrl(artist.name, artist.id)}
              className={className}
            >
              {artist.name}
            </Link>
          ) : (
            <span className={className}>{artist.name}</span>
          )}

          {artists[index + 1] && (
            <span className={`text-sm ${textColor} ${textClassName}`}>x</span>
          )}
        </Fragment>
      ))}
    </div>
  )
}
